import React from 'react';
import './style.css';
// import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function MapAndSocials() {
    return (<div style={{ margin: '50px', flex: '1', display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', width: "100%", height: '80%' }}>
            <div style={{textAlign:'end'}} className="texto-visita">
                <h2 className="titulo-visita">Faça-nos uma visita</h2>
                <p className='subtitulo-map'>Empresarial Soares de Souza, R.</p>
                <p className='subtitulo-map'>Pombos, 200 - Sala 203 - Candeias,</p>
                <p className='subtitulo-map'>Jaboatão dos Guararapes - PE,</p>
                <p className='subtitulo-map'>54440-360</p>
            </div>
            <div className="responsiveMap">
                <iframe  style={{  marginLeft: "20px", marginTop: '30px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.9808799601396!2d-34.921412184688144!3d-8.204676384579113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7aae1e23a5cb7cd%3A0x842aa7b20422ca68!2sKammel&#39;s%20Arquitetura%20e%20Design!5e0!3m2!1spt-BR!2sbr!4v1643943248646!5m2!1spt-BR!2sbr" className="mymap" loading="lazy"></iframe>
            </div>
        </div>

        <div style={{alignContent:'center', display: 'flex',justifyContent: 'flex-end', width: "100%", marginTop: '30px' }}>
            <h3 className='medio-titulo'>Siga nossas redes sociais:</h3>
            <IconButton 
                href="https://www.instagram.com/kammels.arq/"
                target="_blank"
                size="medium" 
                className="icon-map" 
                aria-label="Instagram">
                <InstagramIcon />
            </IconButton>
            <IconButton 
                href="https://www.facebook.com/kammels.arq/" 
                target="_blank"
                className="icon-map" 
                aria-label="Facebook">
                <FacebookIcon />
            </IconButton>
            <IconButton 
                href="https://api.whatsapp.com/send/?phone=5581999120494&text&app_absent=0"
                target="_blank"
                className="icon-map" 
                aria-label="Whatsapp">
                <WhatsAppIcon />
            </IconButton>
        </div>
    </div>);
}

export default MapAndSocials;