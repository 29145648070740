import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/Helvetica/HelveticaRegular.ttf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './Utils/ScrollToTop';


ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop/>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

reportWebVitals();
