import React from "react";
import Navbar from "../../components/NavBar";
import Contacts from "../../components/Contacts";
import Footer from "../../components/Footer";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ConstultoriaTable from "../../components/ConsultoriaTable";
import "./style.css";

function ConsultoriaPage() {

  const style = {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  };
  const [nome, setNome] = React.useState('');
  const [idade, setIdade] = React.useState('');
  const [celular, setCelular] = React.useState('');
  const [cidadeEstado, setCidadeEstado] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [consultoria, setConsultoria] = React.useState('');
  const [extraText, setExtraText] = React.useState('');
  const generateEmailText = () => {
    return `
      Nome=${nome},
      Idade=${idade},
      Celular=${celular},
      Cidade/Estado=${cidadeEstado},
      Email=${email},
      Tipo de consultoria = ${consultoria},
      Mensagem=${extraText}
    `;
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="container">
        <Navbar />

        <h2 className="titulo-consultoria-interna">Consultoria</h2>

        <ConstultoriaTable />

        <div style={{ marginTop: '150px' }} tabIndex="0" id="consultoriaForm" className="questioneer">
          <h2 className="formTitle">Questionário de consulta</h2>
          <p className="formText">
            Neste formulário serão fornecidos as informações necessárias para o
            orçamento. Após esta etapa finalizada, entraremos em contato.
            Esperamos poder fazer parte do seu sonho, muito obrigado!
          </p>
          <TextField
            sx={style}
            className="questionnerTextField"
            id="outlined-basic"
            label="Nome"
            required
            variant="outlined"
            value={nome}
            onChange={(e) => { setNome(e.target.value); }}
          />
          <TextField
            sx={style}
            className="questionnerTextField"
            id="outlined-basic"
            label="Idade"
            required
            value={idade}
            onChange={(e) => {
              if (e.target.value.length <= 3) {
                setIdade(e.target.value);
              }
            }}
            variant="outlined"
          />
          <TextField
            sx={style}
            className="questionnerTextField"
            id="outlined-basic"
            label="Celular"
            required
            value={celular}
            onChange={(e) => {
              if (e.target.value.length <= 13) {
                setCelular(e.target.value);
              }
            }}
            variant="outlined"
          />
          <TextField
            sx={style}
            className="questionnerTextField"
            id="outlined-basic"
            label="Cidade e estado"
            required
            value={cidadeEstado}
            onChange={(e) => { setCidadeEstado(e.target.value); }}
            variant="outlined"
          />
          <TextField
            sx={style}
            className="questionnerTextField"
            id="outlined-basic"
            label="E-mail"
            required
            value={email}
            onChange={(e) => { setEmail(e.target.value); }}
            variant="outlined"
          />
          <div style={{ textAlign: 'center' }}>

            <FormControlLabel
            className="optionText"
              value="lite"
              control={<Checkbox style={{ color: '#000000' }} />}
              label="Consultoria Lite"
              labelPlacement="bottom"
              checked={consultoria == 'lite'}
              onChange={() => { setConsultoria('lite'); }}
            />
            <FormControlLabel
            className="optionText"
              value="standard"
              control={<Checkbox style={{ color: '#000000' }} />}
              label="Consultoria Standard"
              labelPlacement="bottom"
              checked={consultoria == 'standard'}
              onChange={() => { setConsultoria('standard'); }}
            />
            <FormControlLabel
            className="optionText"
              value="ultra"
              control={<Checkbox style={{ color: '#000000' }} />}
              label="Consultoria Ultra"
              labelPlacement="bottom"
              checked={consultoria == 'ultra'}
              onChange={() => { setConsultoria('ultra'); }}
            />

          </div>
          <TextField
            sx={style}
            className="questionnerTextField"
            multiline
            style={{ marginBottom: '50px' }}
            rows={4}
            id="outlined-basic"
            label="Ambiente(s) a ser(em) modificado(s)"
            required
            value={extraText}
            onChange={(e) => { setExtraText(e.target.value); }}
            variant="outlined"
          />
          <Button
            onClick={() => { location.href = `mailto:kammels.arq@gmail.com?subject=${encodeURIComponent('Nova mensagem de consultoria')}&body=${encodeURIComponent(generateEmailText())}`; }}
            className='btnSubmit' key="two">
            Enviar
          </Button>
        </div>

        <Contacts />

      </div>

      <Footer />
    </div>
  );
}

export default ConsultoriaPage;
