import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import './style.css';

const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: p.focusColor
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: p.focusColor
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: p.focusColor
      }
    }
  }));

function ContactForm() {
  const [nome,setNome] = React.useState('');
  const [email,setEmail] = React.useState('');
  const [celular,setCelular] = React.useState('');
  const [extraText,setExtraText] = React.useState('');

  const generateEmailText = ()=>{
    return `
      Nome=${nome},
      Email=${email},
      Telefone=${celular},
      E-mail=${email},
      Mensagem=${extraText}
    `;
  };
    return (
        <div style={{ margin: '50px', flex:'1' }} id="contato">
            <h3 className="titulo-fale-conosco">Fale Conosco</h3>
            <p className="subtitulo">Nos envie uma mensagem preenchendo o formulário, entraremos em contato o mais rápido possível.</p>
            <form>
                <CssTextField
                    focusColor='#828282'
                    type="text"
                    name="nome"
                    fullWidth
                    required
                    margin="normal"
                    id="nome"
                    label="Seu nome"
                    variant="standard"
                    value={nome}
                    onChange={(e)=>setNome(e.target.value)}
                    InputProps={{ style: { fontFamily:'HelveticaRegular' } }}
                    InputLabelProps={{ style: { fontFamily:'HelveticaRegular' } }}
                />

                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <CssTextField
                        focusColor='#828282'
                        required
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        margin="normal"
                        label="Seu e-mail"
                        variant="standard"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        InputProps={{ style: { fontFamily:'HelveticaRegular' } }}
                        InputLabelProps={{ style: { fontFamily:'HelveticaRegular' } }}
                        className="texto-form"
                    />
                    <CssTextField
                        focusColor='#828282'
                        type="phone"
                        required
                        id="telefone"
                        margin="normal"
                        label="Seu telefone"
                        variant="standard"
                        value={celular}
                        onChange={(e)=>{
                          if(e.target.value.length <= 13){
                            setCelular(e.target.value);
                          }
                        }}
                        InputProps={{ style: { fontFamily:'HelveticaRegular' } }}
                        InputLabelProps={{ style: { fontFamily:'HelveticaRegular' } }}
                        className="texto-form"
                    />
                </div>
                <CssTextField
                    focusColor='#828282'
                    fullWidth
                    required
                    multiline
                    margin="normal"
                    maxRows={4}
                    id="mensagem"
                    type="text"
                    label="Mensagem"
                    variant="standard"
                    value={extraText}
                    onChange={(e)=>setExtraText(e.target.value)}
                    InputProps={{ style: { fontFamily:'HelveticaRegular' } }}
                    InputLabelProps={{ style: { fontFamily:'HelveticaRegular' } }}
                    className="texto-form"
                />

                <div style={{ display: 'flex', width: '100%', textAlign: 'left', marginTop: '20px' }}>
                    <Button 
                      style={{ color: '#ffffff', backgroundColor: '#828282', borderColor: '#828282', fontFamily:'HelveticaBold', borderRadius: '50px' }} 
                      variant="outlined" 
                      className="btn-enviar"
                      aria-label="Enviar"
                      onClick={()=>{location.href = `mailto:kammels.arq@gmail.com?subject=${encodeURIComponent('Nova mensagem de Fale Conosco')}&body=${encodeURIComponent(generateEmailText())}`;}}
                      >
                        Enviar
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default ContactForm;