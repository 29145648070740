import React from 'react';
import Navbar from '../../components/NavBar';
import Contacts from '../../components/Contacts';
import Footer from '../../components/Footer';
import './style.css';
import imageWorkshops from '../../images/workshops.png';
import imageBranding from '../../images/branding.png';
import imagePetHome from '../../images/pethome.png';

function NovidadesPage() {

    return (
        <div style={{ width: '100%', height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            
            <div className="container">
                <Navbar />

                <h2 className="titulo-novidades">
                    Novidades
                </h2>

                <div className="novidadeContainer card-style-novidade">
                    <a href="https://forms.gle/HZEQmBiyBbQQbJw67" target="_blank" rel="noreferrer">
                        <img src={imageWorkshops} style={{width:'100%'}} alt="Workshops" title="Workshops" />
                    </a>
                    <p className="innerText2">Workshops</p>
                    <p className="innerText3">Workshops de especializações para estudantes e profissionais da área para aprimorarem os seus conhecimentos e abrangir suas habilidades.</p>
                </div>
                

                <div className="novidadeContainer card-style-novidade">
                    <a href="https://docs.google.com/forms/d/1LShP9-Z01Uk80S3VKPtmH2yM0clg8coGYE1RxTrE-wU/edit" target="_blank" rel="noreferrer">
                        <img src={imageBranding} style={{width:'100%'}} alt="Branding" title="Branding" />
                    </a>
                    <p className="innerText2">Branding</p>
                    <p className="innerText3">O processo de branding é uma possibilidade importante para quem deseja abrir um ponto comercial e além do projeto arquitetônico, produz o branding com todo o processo de logo e identidade visual junto com o nosso escritório.</p>
                </div>

                <div className="novidadeContainer card-style-novidade" style={{marginBottom:'150px'}}>
                    <a href="https://api.whatsapp.com/send/?phone=5581999120494&text&app_absent=0" target="_blank" rel="noreferrer">
                        <img src={imagePetHome} style={{width:'100%'}} alt="PetHome" title="PetHome" />
                    </a>
                    <p className="innerText2">PetHome</p>
                    <p className="innerText3">O PetHome é uma proposta para deixar o seu pet muito bem protegido e abrigado em uma mini residência com todo o luxo, conforto e cuidado que ele merece. O PetHome pode ser projetado adequadamente ao projeto de interiores ou à parte.</p>
                </div>
            
                <Contacts />
            </div>

            <Footer />

        </div>
    );
}

export default NovidadesPage;
