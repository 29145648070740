import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom";


import fachadamdBanner from '../../images/Projeto/FachadaMd/image1Banner.jpeg';
import fachadamd1 from '../../images/Projeto/FachadaMd/image1.jpeg';
import fachadamd2 from '../../images/Projeto/FachadaMd/image2.jpeg';
import fachadamd3 from '../../images/Projeto/FachadaMd/image3.jpeg';
import fachadamd4 from '../../images/Projeto/FachadaMd/image4.jpeg';

import studiocrmBanner from '../../images/Projeto/StudioCRM/banner-crm.png';
import studiocrm1 from '../../images/Projeto/StudioCRM/image1.png';
import studiocrm2 from '../../images/Projeto/StudioCRM/image2.png';
import studiocrm3 from '../../images/Projeto/StudioCRM/image3.png';


import blackRoomBanner from '../../images/Projeto/BlackRoom/image1 banner.png';
import blackroom1 from '../../images/Projeto/BlackRoom/image1.png';
import blackroom2 from '../../images/Projeto/BlackRoom/image2.png';
import blackroom3 from '../../images/Projeto/BlackRoom/image3.png';
import blackroom4 from '../../images/Projeto/BlackRoom/image4.png';

import './style.css';


function CarouselBanner() {
    let navigate = useNavigate();

    const bannerProjects = [


        {
            bannerImage: blackRoomBanner,
            spacing: 4,
            class: 'card-style-2',
            images: [{ img: blackroom1, alt: 'Black Room' }, { img: blackroom2, alt: 'Black Room' }, { img: blackroom3, alt: 'Black Room' }, { img: blackroom4, alt: 'Black Room' }],
            text: 'Black Room',
            address: 'SÃO BENEDITO, OLINDA-PE',
            year: '2023',
            tamanho: '13m²',
            type: 'Residencial',
            description: "Muitos tem receio em escolher tons escuros para o quarto pensando que irá ficar algo desconfortável, mas a verdade é que um projeto bem feito e a iluminação correta pode deixá-lo super aconchegante e moderno, saindo do óbvio das cores claras.",
            link: '/projeto/black-room',
            link360: 'https://meutour360.com.br/tour-360/black-room'
        },
        {
            bannerImage: fachadamdBanner,
            id: 5,
            spacing: 8,
            class: 'card-style-2',
            images: [{ img: fachadamd1, alt: 'Fachada MD' }, { img: fachadamd2, alt: 'Fachada MD' }, { img: fachadamd3, alt: 'Fachada MD' }, { img: fachadamd4, alt: 'Fachada MD' }],
            text: 'Fachada MD',
            address: 'CARUARU-PE',
            year: '2021',
            tamanho: '57m²',
            type: 'Fachada',
            description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
            link: '/projeto/fachada-md',
            double: true,
            link360: false
        },
        {
            id: 6,
            spacing: 4,
            class: 'card-style-2',
            images: [{ img: studiocrm1, alt: 'Studio CRM' }, { img: studiocrm2, alt: 'Studio CRM' }, { img: studiocrm3, alt: 'Studio CRM' }],
            text: 'Studio CRM',
            bannerImage: studiocrmBanner,
            address: 'SANTO AMARO, RECIFE-PE',
            year: '2022',
            tamanho: '57m²',
            type: 'Comercial',
            description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
            link: '/projeto/studio-crm',
            link360: false
        },
    ];

    return (
        <Carousel className="myCarrouselBanner" showThumbs={false} showStatus={false} autoPlay={true}>
            {bannerProjects.map(project => (
                <div className="bannerContainerBanner" key={project.id} onClick={() => { navigate(project.link, { state: project }); }}>
                    <img src={project.bannerImage} alt="banner" />
                    {/* <p className="innerTextBanner3">{project.text}</p> */}
                    <p className="legend">{project.text}</p>
                </div>
            ))}
        </Carousel>
    );

}

export default CarouselBanner;