import React from 'react';
import {Carousel} from 'react-responsive-carousel';


import './style.css';

function CarouselBannerGeneric(props) {

    return (
        <Carousel style={{borderRadius:'7%'}} className="myCarrousel" showArrows={true} showThumbs={false} showStatus={false} autoPlay={true}>
            {props.images.map((image, index) => (
                
                    <div className={'bannerContainerGeneric'} key={index}>
                        <img className="carroselImageProjetoGeneric" src={image.alt?image.img:image} alt={image.alt?image.alt:''} />
                    </div>
                
            ))}
        </Carousel>
    );

}

export default CarouselBannerGeneric;