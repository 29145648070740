import React from "react";
import "./style.css";

import Grid from '@mui/material/Grid';
// import { useNavigate } from 'react-router-dom';
import consultoriaLite from '../../images/icons/consultoriaLite.svg';
import consultoriaStandard from '../../images/icons/consultoriaStandard.svg';
import consultoriaUltra from '../../images/icons/consultoriaUltra.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import './style.css';

function ConstultoriaTable() {
  // const navigate = useNavigate();
  return (
    <div className="container-form">
      <Grid
        container
      >
        <Grid item md={3} key={1} className="responsiveList">
          <div style={{ height: '325px', display: 'flex', alignItems: 'flex-end' }}>
            <p className="itensLight" style={{ color: '#414042' }}> Você vai receber um guia com:</p>
          </div>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Reunião Presencial ou Online</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Moodboard</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Lista de Itens</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Esboço em Planta</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Videoconferência</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Imagens em 3D</h4>
          <h4 className="itensBold" style={{ width: '100%', marginTop: '15px', color: '#414042' }}>Video 360°</h4>
          <p className="itensLight" style={{ color: '#414042', marginTop: '40px' }}>*Para ambientes de 1 a 30m²</p>
        </Grid>
        <Grid item style={{ textAlign: 'center', heigth: '200px', border: '1px solid #E6E7E8', borderRadius: '20px 0 0 20px' }} className="responsiveBorder" md={3} key={2}>
          <div style={{ height: '325px' }}>
            <img
              style={{ marginTop: '60px', marginBottom: '10px' }}
              src={consultoriaLite}
            />
            <h3 className="consultoriaType">Consultoria Lite</h3>
            <p className="consultoriaText" style={{ padding: '0 15px 0 15px' }}>Indicado para quem quer dicas e sugestões de decoração do ambiente.</p>
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Reunião Presencial ou Online</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Moodboard</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Lista de Itens</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Esboço em Planta</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Videoconferência</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Imagens em 3D</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Video 360°</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <Button
            variant="outlined"
            className="btn-enviar-consultoria"
            aria-label="Enviar"
            onClick={() => { document.getElementById('consultoriaForm').focus(); }}
          >
            Saiba Mais
          </Button>
        </Grid>
        <Grid item style={{ textAlign: 'center', heigth: '200px', border: '1px solid #E6E7E8' }} className="responsiveBorder" md={3} key={2}>
          <div style={{ height: '325px' }}>
            <img
              style={{ marginTop: '60px', marginBottom: '10px' }}
              src={consultoriaStandard}
            />
            <h3 className="consultoriaType">Consultoria Standard</h3>
            <p className="consultoriaText" style={{ padding: '0 15px 0 15px' }}>Indicado para quem quer renovar o ambiente seja residencial ou comercial de forma simples, com moveis e revestimentos novos.</p>
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Reunião Presencial ou Online</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Moodboard</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Lista de Itens</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Esboço em Planta</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Videoconferência</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Imagens em 3D</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Video 360°</h4>
            <CloseIcon style={{ color: '#231F20' }} />
          </div>
          <Button
            variant="outlined"
            className="btn-enviar-consultoria"
            aria-label="Enviar"
            onClick={() => { document.getElementById('consultoriaForm').focus(); }}
          >
            Saiba Mais
          </Button>
        </Grid>

        <Grid item style={{ textAlign: 'center', heigth: '200px', border: '1px solid #E6E7E8', borderRadius: '0 20px 20px 0' }} className="responsiveBorder" md={3} key={4}>
          <div className="containerRibbon" data-ribbon="Mais Desejado" style={{ '--d': "0px", "--c": "#50803B", "--f": "18px" }}>
            <div style={{ height: '325px' }}>
              <img
                style={{ marginTop: '60px', marginBottom: '10px' }}
                src={consultoriaUltra}
              />
              <h3 className="consultoriaType">Consultoria Ultra</h3>
              <p className="consultoriaText" style={{ padding: '0 15px 0 15px' }}>Indicado para quem quer renovar o ambiente seja residencial ou comercial de forma simples, mas gostaria de visualizar o ambiente em 3D.</p>
            </div>
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Reunião Presencial ou Online</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Moodboard</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Lista de Itens</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Esboço em Planta</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Videoconferência</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Imagens em 3D</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <div className="responsiveOptionDiv">
            <h4 className="responsiveDescription" style={{ marginTop: '3px', color: '#414042' }}>Video 360°</h4>
            <CheckIcon style={{ color: '#231F20' }} />
          </div>
          <Button
            variant="outlined"
            className="btn-enviar-consultoria"
            aria-label="Enviar"
            onClick={() => { document.getElementById('consultoriaForm').focus(); }}
          >
            Saiba Mais
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConstultoriaTable;
