import React from 'react';
import consultoriaLite from "../../images/icons/consultoriaLite.svg";
import consultoriaStandard from '../../images/icons/consultoriaStandard.svg';
import consultoriaUltra from '../../images/icons/consultoriaUltra.svg';
import './style.css';

function Consultoria() {

    return (

        <div className="bg-consultoria">
            <h2 className='consultoriaTitle' style={{ textAlign: 'center', marginBottom: '45px' }}>Consultoria</h2>
            <div className="row">
                <div className="card">
                    <div className="tamanho-card">
                        <div className="card-header">
                            <img src={consultoriaLite} alt="Consultoria Lite" title="Consultoria Lite" className="img-consultoria" />
                            <h2 className='consultoriaTema'>Consultoria Lite</h2>
                        </div>
                        <p className="descricao-consultoria">
                            Indicado para quem quer dicas e sugestões para a decoração do ambiente.
                        </p>
                    </div>
                    <a href="/consultoria" className="btn-saibamais row">Saiba mais</a>
                </div>
                <div className="card">
                    <div className="tamanho-card">
                        <div className="card-header">
                            <img src={consultoriaStandard} alt="Consultoria Standard" title="Consultoria Standard" className="img-consultoria" />
                            <h2 className='consultoriaTema'>Consultoria Standard</h2>
                        </div>

                        <p className="descricao-consultoria">
                            Indicado para quem quer renovar o ambiente dentro de casa de forma simples, com móveis novos e pintura de paredes.
                        </p>
                    </div>
                    <a href="/consultoria" className="btn-saibamais row">Saiba mais</a>
                </div>
                <div className="card">
                    <div className="tamanho-card">
                        <div className="containerRibbon" data-ribbon="Mais Desejado" style={{'--d':"0px","--c":"#50803B","--f":"18px"}}>
                        <div className="card-header">
                            <img src={consultoriaUltra} alt="Consultoria Ultra" title="Consultoria Ultra" className="img-consultoria" />
                            <h2 className='consultoriaTema'>Consultoria Ultra</h2>
                        </div>
                        </div>
                        <p className="descricao-consultoria">
                            Indicado para quem quer renovar o ambiente dentro de casa de forma simples, mas gostaria de visualizar o ambiente em 3D.
                        </p>
                    </div>
                    <a href="/consultoria" className="btn-saibamais row">Saiba mais</a>
                </div>
            </div>
        </div>
    );
}

export default Consultoria;