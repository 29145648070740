import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logokammels from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './style.css';

const pages = ['Início', 'Sobre nós', 'Projetos', 'Consultoria', 'Novidades', 'Contato'];
const pagesLink = ['/','/sobrenos','/projetos','/consultoria','/novidades','/#contacts'];
const pagesResponsive = ['Início', 'Sobre nós', 'Projetos', 'Consultoria', 'Novidades', 'Contato', 'Instagram','Facebook','WhatsApp'];
const pagesResponsiveLink = ['/','/sobrenos','/projetos','/consultoria','/novidades','/','/#contato','/','/','/'];

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const location = useLocation();  // get the current location
  return (
    <AppBar className="myNavBar">
      <Container maxWidth="false">
        <Toolbar disableGutters>

        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <a href="/">
              <img src={logokammels} alt="logo" className="logo2"  />
            </a>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
            <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pagesResponsive.map((page,index) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography onClick={()=>{
                    if(pagesResponsiveLink[index] == "/#contacts" && location.pathname == "/"){
                      document.getElementById('contacts').focus();
                    }else{
                      navigate(pagesResponsiveLink[index]);
                    }
                    }} textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img src={logokammels} alt="logo" className="logo"  />
          </Typography>

          <Box style={{justifyContent:"space-around",margin:"0 200px"}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page,index) => (
              <Button
                className={pagesLink[index] == location.pathname?"menuOptionSelected":"menuOption"}
                key={page}
                onClick={()=>{
                  if(pagesLink[index] == "/#contacts" && location.pathname == "/"){
                    document.getElementById('contacts').focus();
                  }else{
                    navigate(pagesLink[index]);
                  }
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
            <div style={{ alignContent: 'center', display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
              <IconButton
                size="small"
                href="https://www.instagram.com/kammels.arq/"
                target="_blank"
                className="icon-nav"
                aria-label="instagram">
                <InstagramIcon />
              </IconButton>
              <IconButton
                size="small"
                href="https://www.facebook.com/kammels.arq/"
                target="_blank"
                className="icon-nav"
                aria-label="facebook">
                <FacebookIcon />
              </IconButton>
              <IconButton
                size="small"
                href="https://api.whatsapp.com/send/?phone=5581999120494&text&app_absent=0"
                target="_blank"
                className="icon-nav"
                aria-label="whatsapp">
                <WhatsAppIcon />
              </IconButton>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;