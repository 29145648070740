import React,{useEffect} from 'react';
import ContactForm from '../../components/ContactForm';
import Contacts from '../../components/Contacts';
import MapAndSocials from '../../components/MapAndSocials';
import About from '../../components/About';
import Consultoria from '../../components/Consultoria';
import Footer from '../../components/Footer';
import './style.css';
import CarouselBanner from '../../components/CarouselBanner (op2)';
import NavBar from '../../components/NavBar';

function HomePage() {
    useEffect(() => {
        // Atualiza o título do documento usando a API do browser
        if(location.hash == '#contacts'){
            document.getElementById('contacts').focus();
        }
    });
    return (
        <div style={{ width: '100%', height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            
            <div className="container">
                <NavBar/>
                <CarouselBanner />
                <About />
                <Consultoria />

                <div tabIndex="2" id="contacts" style={{ width: '100%', display: 'flex', flexWrap: 'wrap', backgroundColor: '#F6F6F6' }} >
                    <ContactForm id="sec4" />
                    <MapAndSocials />
                </div>

                <Contacts />
            </div>

            <Footer />
        </div>
    );
}

export default HomePage;