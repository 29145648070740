import React from 'react';
import escritorio1 from '../../images/escritorio1.svg';
import escritorio2 from '../../images/escritorio2.svg';
import escritorio3 from '../../images/escritorio3.svg';
import './style.css';
import Grid from '@mui/material/Grid';

function About() {

  return (
    <div>
      <div className="about-container">
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >

          <Grid item md={12} lg={5} className="about-item">
            <h1 className='titulo'>
              O que você sonha, nós realizamos.
            </h1>
            <p className='subtitulo-home'>
              {`Acreditamos em uma arquitetura que molde o comportamento das pessoas onde ela for inserida. Exploramos maneiras de enriquecer a vida das pessoas que vivem, compartilham ou trabalham dentro e ao redor dos espaços onde projetamos.`}
            </p>
            <p className='subtitulo-home'>
              O projeto é feito dentro do orçamento do cliente, entregando acessibilidade e funcionalidade.
            </p>
          </Grid>

          <Grid item sm={12} lg className="about-item">
            <img
              style={{ textAlign:'center',width: '80%', borderRadius: '50px' }}
              src={escritorio1}
              alt="Kammel's Arquitetura e Design"
              title="Kammel's Arquitetura e Design"
            />
          </Grid>

          <Grid item sm={12} lg className="about-item">
            <img
              style={{ width: '80%', borderRadius: '60px' }}
              src={escritorio2}
              alt="Kammel's Arquitetura e Design"
              title="Kammel's Arquitetura e Design"
            />
          </Grid>

          <Grid item sm={12} lg className="about-item">
            <img
              style={{ width: '80%', borderRadius: '50px' }}
              src={escritorio3}
              alt="Kammel's Arquitetura e Design"
              title="Kammel's Arquitetura e Design"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default About;