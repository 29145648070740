import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Contacts from '../../components/Contacts';
import Footer from '../../components/Footer';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import './style.css';

const button1 = [
    <Button className='btnGoBack' key="one">Retornar à página anterior</Button>,
];
const button2 = [
    <Button className='btnGoHome' key="two">Ir para a página inicial</Button>,
];

function PageNotFound() {
    const navigate = useNavigate();
    return(
        <div style={{ width: '100%', height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="container">
                <NavBar/>

                <div className="container-not-found">
                    <h2 className="titulo-not-found">
                        404
                    </h2>

                    <h2 className="subtitulo-not-found">
                        Página não encontrada!
                    </h2>

                    <p className="texto-not-found">
                        A página que você tentou acessar está indisponível ou não existe. 
                    </p>

                    <div>
                        <Box
                            style={{justifyContent: 'center'}}
                            sx={{
                                display: 'flex',
                                '& > *': {
                                m: 1,
                                },
                            }}
                        >
                            <ButtonGroup
                                orientation="horizontal"
                                
                                aria-label="Retornar à página anterior"
                                onClick={()=>{
                                    navigate(-1);
                                }}
                            >
                                {button1}
                            </ButtonGroup>
                            <ButtonGroup
                                orientation="horizontal"
                                aria-label="Ir para a página inicial"
                                variant="contained"
                                onClick={()=>{navigate('/');}}
                            >
                                {button2}
                            </ButtonGroup>
                        </Box>
                    </div>

                </div>

                <Contacts />
            </div>
            <Footer />
        </div>
    );
}

export default PageNotFound;