import React from 'react';
import NavBar from '../../components/NavBar';
import Contacts from '../../components/Contacts';
import Footer from '../../components/Footer';
import { useLocation } from "react-router-dom";
import CarouselBannerGeneric from '../../components/CarouselBannerGeneric';
import { ReactComponent as EnderecoLogo } from '../../images/icons/icone_endereco.svg';
import { ReactComponent as AnoLogo } from '../../images/icons/icone_ano_projeto.svg';
import { ReactComponent as TamanhoLogo } from '../../images/icons/icone_tamanho.svg';
import { ReactComponent as Logo360 } from '../../images/icons/icone_360.svg';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import './style.css';

function Projeto() {


    let { state } = useLocation();
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/projetos" >
            Nossos Projetos
        </Link>,
        <Typography key="3" color="text.primary">
            {state.text}
        </Typography>,
    ];
    return (
        <div style={{ width: '100%', height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>

            <div id="start" className="container">
                <NavBar />
                <div className={'projetoTitle'}>
                    <Breadcrumbs
                        className={'breadcrumbs'}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>

                </div>
                {state.double ? <CarouselBannerGeneric images={state.images.slice(0,Math.floor(state.images.length/2))} /> : <></>}
                <CarouselBannerGeneric 
                    class={state.class} 
                    images={state.double?state.images.slice(Math.floor(state.images.length/2)):state.images} 
                />

                <div className="projectInfo">
                    <h2 className="titulo-projeto">
                        {state.text}
                    </h2>
                </div>

                <div className="projectInfo">
                    <div style={{ display: 'flex',flexWrap:'wrap' }}>
                        <EnderecoLogo />
                        <h3 className='projetoInfoTitle'>ENDEREÇO: </h3>
                        <p className="projectInfoText">{state.address}</p>
                    </div>
                </div>

                <div className="projectInfo">
                    <div style={{ display: 'flex',flexWrap:'wrap' }}>
                        <AnoLogo />
                        <h3 className='projetoInfoTitle'>ANO DO PROJETO: </h3>
                        <p className="projectInfoText">{state.year}</p>
                    </div>
                </div>

                <div className="projectInfo">
                    <div style={{ display: 'flex',flexWrap:'wrap' }}>
                        <TamanhoLogo />
                        <h3 className='projetoInfoTitle'>TAMANHO: </h3>
                        <p className="projectInfoText">{state.tamanho}</p>
                    </div>
                </div>

                <div className="projectInfo">
                    <p className="projectInfoAbout">{state.description}</p>
                </div>

                {state.link360 ?
                <div className="link360">
                    <a className="projeto-360" target="_blank" href={state.link360} rel="noreferrer">
                        <Logo360 />
                        <p>
                            Veja o projeto em 360º
                        </p>
                    </a>
                </div>
                : <></>}

                <Contacts />
            </div>

            <Footer />

        </div>
    );
}

export default Projeto;