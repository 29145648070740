import React from 'react';
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Contacts from '../../components/Contacts';
import { CardMedia } from '@mui/material';
import './style.css';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import fischer1 from '../../images/Projeto/Fischer/image1.jpeg';
import fischer2 from '../../images/Projeto/Fischer/image2.jpeg';
import fischer3 from '../../images/Projeto/Fischer/image3.jpeg';

import residencianl1 from '../../images/Projeto/ResidenciaNL/image1.png';
import residencianl2 from '../../images/Projeto/ResidenciaNL/image2.png';
import residencianl3 from '../../images/Projeto/ResidenciaNL/image3.png';
import residencianl4 from '../../images/Projeto/ResidenciaNL/image4.png';
import residencianl5 from '../../images/Projeto/ResidenciaNL/image5.png';
import residencianl6 from '../../images/Projeto/ResidenciaNL/image6.png';
import residencianl7 from '../../images/Projeto/ResidenciaNL/image7.png';

import suitevictoria1 from '../../images/Projeto/SuiteVictoria/image1.png';
import suitevictoria2 from '../../images/Projeto/SuiteVictoria/image2.png';
import suitevictoria3 from '../../images/Projeto/SuiteVictoria/image3.png';
import suitevictoria4 from '../../images/Projeto/SuiteVictoria/image4.png';
import suitevictoria5 from '../../images/Projeto/SuiteVictoria/image5.png';
import suitevictoria6 from '../../images/Projeto/SuiteVictoria/image6.png';
import suitevictoria7 from '../../images/Projeto/SuiteVictoria/image7.png';

import flat17041 from '../../images/Projeto/Flat1704/image1.png';
import flat17042 from '../../images/Projeto/Flat1704/image2.png';
import flat17043 from '../../images/Projeto/Flat1704/image3.png';
import flat17044 from '../../images/Projeto/Flat1704/image4.png';

import fachadamd1 from '../../images/Projeto/FachadaMd/image1.jpeg';
import fachadamd2 from '../../images/Projeto/FachadaMd/image2.jpeg';
import fachadamd3 from '../../images/Projeto/FachadaMd/image3.jpeg';
import fachadamd4 from '../../images/Projeto/FachadaMd/image4.jpeg';

import studiocrm1 from '../../images/Projeto/StudioCRM/image1.png';
import studiocrm2 from '../../images/Projeto/StudioCRM/image2.png';
import studiocrm3 from '../../images/Projeto/StudioCRM/image3.png';

import blackroom1 from '../../images/Projeto/BlackRoom/image1.png';
import blackroom2 from '../../images/Projeto/BlackRoom/image2.png';
import blackroom3 from '../../images/Projeto/BlackRoom/image3.png';
import blackroom4 from '../../images/Projeto/BlackRoom/image4.png';

let projetos = [{
  id:1,
  spacing:6,
  class:'card-style-2',
  images:[{img:fischer1,alt:'fischer1'},{img:fischer2,alt:'fischer2'},{img:fischer3,alt:'fischer3'}],
  text:'Suíte Fischer',
  address:'CANDEIAS, JABOATÃO DOS GUARARAPES-PE',
  year:'2021',
  tamanho:'52m²',
  type:'Residencial',
  description:"A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
  link:'/projeto/suite-fischer',
  link360: false
},
{
  id:2,
  spacing:6,
  class:'card-style-2',
  images:[{img:residencianl3,alt:'Residência NL'},{img:residencianl1,alt:'Residência NL'},{img:residencianl2,alt:'Residência NL'},{img:residencianl4,alt:'Residência NL'},{img:residencianl5,alt:'Residência NL'},{img:residencianl6,alt:'Residência NL'}, {img:residencianl7,alt:'Residência NL'}],
  text:'Residência NL',
  address:'JARDIM SÃO PAULO, RECIFE-PE',
  year:'2020',
  tamanho:'230m²',
  type:'Residencial',
  description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
  link:'/projeto/residencia-nl',
},{
  id:3,
  spacing:6,
  class:'card-style-2',
  images:[{img:suitevictoria1,alt:'Suíte Victória'},{img:suitevictoria2,alt:'Suíte Victória'},{img:suitevictoria3,alt:'Suíte Victória'},{img:suitevictoria4,alt:'Suíte Victória'},{img:suitevictoria5,alt:'Suíte Victória'},{img:suitevictoria6,alt:'Suíte Victória'}, {img:suitevictoria7,alt:'Suíte Victória'}],
  text:'Suíte Victória',
  address:'CASA FORTE, RECIFE-PE',
  year:'2021',
  tamanho:'15m²',
  type: 'Residencial',
  description: "Esta linda suíte da princesa Victória foi planejada de modo que conforme ela vá crescendo o quarto consiga acompanhar seu crescimento, com papel de parede menos infantil, tons mais claros e poucos móveis para ganhar mais espaço.",
  link:'/projeto/suite-victoria',
  link360: 'https://meutour360.com.br/tour-360/suite-v'
},
{
  id:4,
  spacing:6,
  class:'card-style-2',
  images:[{img:flat17041,alt:'Flat 1704'},{img:flat17042,alt:'Flat 1704'},{img:flat17043,alt:'Flat 1704'},{img:flat17044,alt:'Flat 1704'}],
  text:'Flat 1704',
  address:'BARRA DE JANGADA, JABOATÃO DOS GUARARAPES-PE',
  year:'2020',
  tamanho:'57m²',
  type:'Residencial',
  description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
  link:'/projeto/flat-1704',
  link360: 'https://meutour360.com.br/tour-360/suite-26'
},{
  id:5,
  spacing:8,
  class:'card-style-2',
  images:[{img:fachadamd1,alt:'Fachada MD'}, {img:fachadamd2,alt:'Fachada MD'}, {img:fachadamd3,alt:'Fachada MD'}, {img:fachadamd4,alt:'Fachada MD'}],
  text:'Fachada MD',
  address:'CARUARU-PE',
  year:'2021',
  tamanho:'57m²',
  type: 'Fachada',
  description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
  link:'/projeto/fachada-md',
  double: true,
  link360: false
},
{
  id:6,
  spacing:4,
  class:'card-style-2',
  images:[{img:studiocrm1, alt:'Studio CRM'}, {img:studiocrm2, alt:'Studio CRM'}, {img:studiocrm3, alt:'Studio CRM'}],
  text:'Studio CRM',
  address:'SANTO AMARO, RECIFE-PE',
  year:'2022',
  tamanho:'57m²',
  type:'Comercial',
  description: "A mescla de materiais como pedra e madeira nesta suíte torna o ambiente rústico e moderno em simultâneo. Foi feito o uso de iluminação indireta para deixar o ambiente mais aconchegante e intimista.",
  link:'/projeto/studio-crm',
  link360: false
},
{
  id:7,
  spacing:4,
  class:'card-style-2',
  images:[{img:blackroom1, alt:'Black Room'}, {img:blackroom2, alt:'Black Room'}, {img:blackroom3, alt:'Black Room'}, {img:blackroom4, alt:'Black Room'}],
  text:'Black Room',
  address:'SÃO BENEDITO, OLINDA-PE',
  year:'2023',
  tamanho:'13m²',
  type:'Residencial',
  description: "Muitos tem receio em escolher tons escuros para o quarto pensando que irá ficar algo desconfortável, mas a verdade é que um projeto bem feito e a iluminação correta pode deixá-lo super aconchegante e moderno, saindo do óbvio das cores claras.",
  link:'/projeto/black-room',
  link360: 'https://meutour360.com.br/tour-360/black-room'
},
];

let typeTranslation = {
  0:true,
  1:'Residencial',
  2:'Comercial',
  3:'Fachada'
};


function Projetos() {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(0);
  let filteredProjetos = projetos.filter(projeto => {
    if(selected==0) return true;
    return projeto.type == typeTranslation[selected];
  });
  
  return (
    <div style={{ width: '100%', height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>

      <div className="container">
        <Navbar />

        <h2 className="titulo-novidades">
          Nossos Projetos
        </h2>
        <div className="outsidediv" >
          <div className="selector">
            <div className={selected===0?'selector-optionactive':'selector-option'}>
              <Typography  onClick={()=>{setSelected(0);}} textAlign="left">Todos</Typography>
            </div>
            <div className={selected===1?'selector-optionactive':'selector-option'}>
              <Typography  onClick={()=>{setSelected(1);}} textAlign="left">Residencial</Typography>
            </div>
            <div className={selected===2?'selector-optionactive':'selector-option'}>
              <Typography  onClick={()=>{setSelected(2);}} textAlign="left">Comercial</Typography>
            </div>
            <div className={selected===3?'selector-optionactive':'selector-option'}>
              <Typography  onClick={()=>{setSelected(3);}} textAlign="left">Fachada</Typography>
            </div>

          </div>

          <Grid container gridTemplateColumns="repeat(12, 1fr)" spacing={5}>
            {filteredProjetos.map(projeto => (
            <Grid 
              key={projeto.id} 
              item xs={12} 
              sm={projeto.spacing} 
              md={projeto.spacing}
            >
              <div 
                style={{ height: '100%' }} 
                className={projeto.class} 
                onClick={()=>{navigate(projeto.link,{state:projeto});}}
              >
                <CardMedia 
                  style={{ borderRadius: '5%' }} 
                  width="100%" height="100%" 
                  component="img" 
                  image={projeto.images[0].alt?projeto.images[0].img:projeto.images[0]}>
                </CardMedia>

                <p className='innerTextProjetos'>
                  {projeto.text}
                </p>
              </div>
            </Grid>
            ))}
          </Grid>
        </div>

        <Contacts/>
      </div>

      <Footer />

    </div>

  );
}

export default Projetos;