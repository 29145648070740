import './App.css';
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import HomePage1 from './pages/HomePage(banner1)';
import HomePage2 from './pages/HomePage(banner2)';
import HomePage3 from './pages/HomePage(banner3)';
import NovidadesPage from './pages/NovidadesPage';
import SobreNosPage from './pages/SobreNosPage';
import ConsultoriaPage from './pages/ConsultoriaPage';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Projetos from './pages/Projetos';
import Projeto from './pages/Projeto';
import PageNotFound from './pages/PageNotFound';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function App() {
  return (
    <div style={{ width: '100%', height: '100%', minHeight: '100%' }}>
      <Routes>
        <Route path='/consultoria' element={<ConsultoriaPage/>} />
        <Route path='/sobrenos' element={<SobreNosPage/>} />
        <Route path='/novidades' element={<NovidadesPage/>} />
        <Route path='/projetos' element={<Projetos/>} />
        <Route path='/projeto/*' element={<Projeto/>} />
        <Route path='/home1' element={<HomePage1/>} />
        <Route path='/home2' element={<HomePage2/>} />
        <Route path='/home3' element={<HomePage3/>} />
        <Route path='/' element={<HomePage/>} />
        <Route path='*' element={<PageNotFound/>} />
      </Routes>
    </div>
  );
}

export default App;