import React from 'react';
import Grid from '@mui/material/Grid';
import Navbar from '../../components/NavBar';
import Contacts from '../../components/Contacts';
import Footer from '../../components/Footer';
import './style.css';
import imageLogoSobre from '../../images/sobrenos/logo.png';
import imageIgor from '../../images/sobrenos/igor_bezerra.jpg';
import imageMariana from '../../images/sobrenos/mariana_camelo.png';
import imageIconaHub from '../../images/sobrenos/logo_icona_hub.svg';
import imageJcCamarasFrigorificas from '../../images/sobrenos/logo_jc_camaras_frigorificas.jpeg';
import projeto_arquitetonico from '../../images/icons/projeto_arquitetonico.svg';
import projeto_interiores from '../../images/icons/projeto_interiores.svg';
import projeto_fachada from '../../images/icons/projeto_fachada.svg';
import levantamento_regularizacao from '../../images/icons/levantamento_regularizacao.svg';
import auxilio_compras from '../../images/icons/auxilio_compras.svg';
import CarrouselBanner from '../../components/CarouselBannerUs';

function SobreNosPage() {
    return (
        <div className="sobre-nos">
            <div className="container">
                <Navbar />

                <div className="container-sobre-nos">
                    <div className="texto-sobre-nos">
                        <h2 className="titulo-sobre-nos">
                            Quem somos
                        </h2>

                        <p>
                            Somos arquitetos pernambucanos com uma visão vanguardista, abrindo o caminho para novas ideias, conceitos e processos da arquitetura e design.
                            <br></br><br></br>
                            Sempre visando resultados à frente do nosso tempo, com soluções que resultem em leveza, aconchego e singularidade para cada projeto desenvolvido.
                            <br></br><br></br>
                            Acreditamos em uma arquitetura que inove, transmita emoções e seja um marco.
                        </p>
                    </div>

                    <div className="img-sobre-nos">
                        <img
                            style={{ width: "80%" }}
                            src={imageLogoSobre}
                            alt="Kammel's Arquitetura e Design"
                            title="Kammel's Arquitetura e Design"
                        />
                    </div>
                </div>

                <div className="nossa-equipe">
                    <h2 className="titulo-nossa-equipe">
                        Nossa Equipe
                    </h2>

                    <div className="equipe-card-container">
                        <div className="equipe-card">
                            <img
                                style={{ width: "100%", borderRadius: '15%'}}
                                src={imageIgor}
                                alt="Igor Bezerra - Arquiteto e Designer"
                                title="Igor Bezerra - Arquiteto e Designer"
                            />
                            <p className="titulo-integrante">Igor Bezerra</p>
                            <p className="cargo">Arquiteto e Designer</p>
                            <p className="bio">
                                Formado em Design Gráfico na UNIAESO em 2013 e em Arquitetura e Urbanismo na Faculdade Esuda em 2019. Capacitações em Product Design, Branding, Marketing digital, Arquitetura Paramétrica, Fachadas inteligentes, Design de interfaces.
                            </p>
                        </div>

                        <div className="equipe-card">
                            <img
                                style={{ width: "100%" , borderRadius: '15%'}}
                                src={imageMariana}
                                alt="Mariana Camêlo - Arquiteta"
                                title="Mariana Camêlo - Arquiteta"
                            />
                            <p className="titulo-integrante">Mariana Camêlo</p>
                            <p className="cargo">Arquiteta</p>
                            <p className="bio">
                                Formada em Arquitetura e Urbanismo na UNICAP em 2020. Capacitações em Acessibilidade, Maquetes 3d, Design de Interiores e Técnica em Iluminação.
                            </p>
                        </div>

                    </div>
                </div>

                <div className="especialidades">
                    <h2 className="titulo-nossa-equipe">
                        Especialidades
                    </h2>

                    <div className="especialidades-container">
                        <Grid 
                            container 
                            spacing={2} 
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs className="especialidade-item">
                                <div className="circulo">
                                    <img
                                        style={{width: '70%'}}
                                        src={projeto_arquitetonico}
                                        alt="Projeto Arquitetônico"
                                        title="Projeto Arquitetônico"
                                    />
                                </div>
                                <div>
                                    <p className="titulo-especialidade">
                                        Projeto Arquitetônico
                                    </p>
                                    <p className="texto-especialidade">
                                        Projetos arquitetônicos do zero ou de reforma                                    
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs className="especialidade-item">
                                <div className="circulo">
                                    <img
                                        style={{width: '60%'}}
                                        src={projeto_interiores}
                                        alt="Projeto de Interiores"
                                        title="Projeto de Interiores"
                                    />
                                </div>
                                <div>
                                    <p className="titulo-especialidade">
                                        Projeto de Interiores
                                    </p>
                                    <p className="texto-especialidade">
                                        Projeto de arquitetura de interiores comercial e residencial                                    
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs className="especialidade-item">
                                <div className="circulo">
                                    <img
                                        style={{width: '60%'}}
                                        src={projeto_fachada}
                                        alt="Projeto de Fachada"
                                        title="Projeto de Fachada"
                                    />
                                </div>
                                <div>
                                    <p className="titulo-especialidade">
                                        Projeto de Fachada
                                    </p>
                                    <p className="texto-especialidade">
                                        Projeto de arquitetura paisagística
                                    </p>
                                </div>
                            </Grid>
                            
                            <Grid item xs className="especialidade-item">
                                <div className="circulo">
                                    <img
                                        style={{width: '60%'}}
                                        src={levantamento_regularizacao}
                                        alt="Levantamento para Regularização"
                                        title="Levantamento para Regularização"
                                    />
                                </div>
                                <div>
                                    <p className="titulo-especialidade">
                                        Levantamento para Regularização
                                    </p>
                                    <p className="texto-especialidade">
                                        Levantamento arquitetônico para quem necessita regularizar o terreno na prefeitura no cartório
                                    </p>
                                </div>
                            </Grid>

                            <Grid item xs className="especialidade-item">
                                <div className="circulo">
                                    <img
                                        style={{width: '60%'}}
                                        src={auxilio_compras}
                                        alt="Auxílio em compras de Imóveis/Terrenos"
                                        title="Auxílio em compras de Imóveis/Terrenos"
                                    />
                                </div>
                                <div>
                                    <p className="titulo-especialidade">
                                        Auxílio em compras de Imóveis/Terrenos
                                    </p>
                                    <p className="texto-especialidade">
                                        Auxílio na compra de terrenos e imóveis com melhor localização, tamanho, ventilação e insolação                                  
                                    </p>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </div>

                <div className="nosso-escritorio">
                    <div className="texto-sobre-nos">
                        <h2 className="titulo-sobre-nos">
                            Escritório
                            </h2>

                        <p>
                            Em nosso escritório cada detalhe tem um significado que nos representa. Ele é o nosso cartão de visitas. E propositalmente, levamos elementos que representam  nossa trajetória até aqui e referências da nossa região. Trazendo a leveza e aconchego em nosso ambiente de trabalho para que nossas reuniões, planejamentos e projetos consigam fluir da melhor forma possível.
                                <br></br><br></br>
                                O escritório fica localizado na zona sul da Região Metropolitana do Recife, em Candeias, Jaboatão dos Guararapes-PE.</p>
                                <p>Estamos prontos para lhe receber e ajudar a realizar seu sonho.</p>
                        </div>
                        <div className='carrosel-sobre-nos'>
                            <CarrouselBanner/>
                        </div>
                </div>


                <div className="parceiros">
                    <h2 className="titulo-nossa-equipe">
                        Parceiros
                    </h2>

                    <div className="parceiros-container">
                        <div className="img-parceiros">
                            <a 
                                target="_blank" 
                                rel="noreferrer"
                                href="https://www.behance.net/igorbezerralves/projects"
                            >
                                <img
                                    src={imageIconaHub}
                                    alt="icona Hub"
                                    title="icona Hub"
                                />
                            </a>
                        </div>
                        <div className="img-parceiros">
                            <a 
                                target="_blank" 
                                rel="noreferrer"
                                href="https://www.jccamarasfrigorificas.com.br/"
                            >
                                <img
                                    className='img-parceiro-jc'
                                    src={imageJcCamarasFrigorificas}
                                    alt="Jc Câmaras Frigoríficas"
                                    title="Jc Câmaras Frigoríficas"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <Contacts />
            </div>

            <Footer />
        </div>
    );
}

export default SobreNosPage;
