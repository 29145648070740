import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import nos1 from '../../images/sobrenos/nos1.jpeg';
import escritorio from '../../images/sobrenos/escritorio.jpg';
import nos3 from '../../images/sobrenos/nos3.jpeg';

import './style.css';

function CarouselBanner() {

    return (
        <Carousel style={{borderRadius:'7%'}}className="myCarrousel" showArrows={false} showThumbs={false} showStatus={false} autoPlay={true}>
                <div className="bannerContainerUs">   
                    <img className="carroselImageUs" src={escritorio} alt="Escritório" />
                </div>
                <div className="bannerContainer">
                    <img className="carroselImageUs" src={nos1} alt="Escritório" />
                </div>
                <div className="bannerContainer">
                    <img className="carroselImageUs" src={nos3} alt="Escritório" />
                </div>
        </Carousel>
    );

}

export default CarouselBanner;