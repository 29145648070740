import React from 'react';
import './style.css';

function Footer() {
    return (
        <div className="rodape">
            <p>{"Kammel's Arquitetura e Design © "+  (1900 + new Date().getYear()) +" - Todos os direitos reservados."}</p>
        </div>
    );
}

export default Footer;
    